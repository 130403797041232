@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

.orderCardContent {
    display: flex;
    gap: $ingredients-unit * 3;
    flex-direction: column;
}

.paymentFailedCard {
    border: solid 1px $colour-error-red;
}