@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';

//
// Variables
// --------------------------------------------------

//== Colors
//
//##

// @deprecated
$colour-lime:                           $colour-corporate-lime-green;

// @deprecated
$colour-waitrose-green-lime:            $colour-corporate-lime-green;

// @deprecated
$colour-waitrose-green-new:             $colour-new-green;

// @deprecated
$colour-waitrose-green:                 $colour-waitrose-green-old;

// @deprecated
$colour-waitrose-cornfield:          $colour-warning-tint;

// @deprecated
$colour-waitrose-error-red:          $colour-error-red;

// @deprecated
// stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
$colour-waitrose-offer-red-option-a: #de3f2e;

// @deprecated
$colour-waitrose-yellow:             $colour-warning;

// @deprecated
$colour-waitrose-campaign-green:      $colour-corporate-green;

// @deprecated
$colour-waitrose-campaign-green-light:  $colour-new-green;
$brand-primary:         $colour-waitrose-green !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               $colour-white !default;

//** Global text color on `<body>`.
$text-color: $colour-primary-grey !default;

//** Global textual link color.
$link-color:            $text-color !default;
$link-hover-color:      $text-color !default;

//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-path-waitrose:      'fonts/waitrose';
$font-path-content:       'fonts/content';
$font-family-base:        'Waitrose', 'Gill Sans', 'Gill Sans MT', Tahoma, sans-serif !default;
$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.125)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * .875)) !default; // ~14px
$font-size-very-small:    ceil(($font-size-base * .75)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 1.875)) !default; // ~30px
$font-size-h2:            floor(($font-size-base * 1.5)) !default; // ~24px
$font-size-h3:            ceil(($font-size-base * 1.375)) !default; // ~22px
$font-size-h4:            ceil(($font-size-base * 1.125)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * .875)) !default; // ~14px

// Waitrose style guide
// check it out: https://docs.google.com/presentation/d/1iEjnLd9uJygTidmWb-edbi6J8ykB9hQTemg-LrgowX8/edit#slide=id.g1378987f64_0_0

$font-waitrose-title-size-large:    ceil(($font-size-base * 1.75)) !default; // ~28px
$font-waitrose-title-size-medium:    ceil(($font-size-base * 1.5)) !default; // ~24px
$font-waitrose-title-size-small:    ceil(($font-size-base * 1.25)) !default; // ~20px

$font-waitrose-body-size-large:    ceil(($font-size-base * 1.25)) !default; // ~20px
$font-waitrose-body-size-medium:    ceil(($font-size-base * 1.125)) !default; // ~18px
$font-waitrose-body-size-small:    $font-size-base; // 16px

$font-body-size-small: ceil(($font-size-base * .875)) !default; //14px;
$font-body-size-very-small: ceil(($font-size-base * .75)) !default; //12px;

$font-size-header-large:  ceil($font-size-base * 2.125) !default; // ~34px
$font-size-header-small:  ceil($font-size-base * 1.75) !default; // ~28px

// fonts

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.25 !default; // 24/16
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    Waitrose !default;
$headings-font-weight:    700 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   20px !default;
$padding-xxl-vertical:      32px !default;
$padding-xl-vertical:       16px !default;
$padding-xl-horizontal:     32px !default;
$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;
$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;
$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;
$margin-base-vertical:      20px !default;
$margin-small-vertical:     16px !default;
$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;
$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-header:             50 !default;
$zindex-input-overlay:      200 !default;
$zindex-pod-overlay:        500 !default;
$zindex-navbar:             1000 !default;
$zindex-dropdown:           1000 !default;
$zindex-combo-dropdown:     1020 !default;
$zindex-pinbar:             1025 !default;
$zindex-navbar-fixed:       1030 !default;
$zindex-modal-background:   1060 !default;
$zindex-modal:              1070 !default;
$zindex-popover:            1080 !default;
$zindex-tooltip:            1090 !default;
$zindex-skip-links:         9000 !default;
$zindex-cookie-consent:     9100 !default;


//== Media queries breakpoints
$grid-breakpoints: (
  'xs': 0,
  'sm': 544px,
  'md': 768px,
  'lg': 992px,
  'xl': 1260px
) !default;

//== Additional media breaks for new pod image design
$pod-image-xxs-breakpoint:    288px;
$pod-image-xs-breakpoint:     343px;

//== New pod image sizes
$pod-image-xxs-width:         85px;
$pod-image-xs-width:         125px;
$pod-image-regular-width:    150px;

// Proportional grid config
$grid-config: (
  columns: 12,
  grid-margin: 16px,
  grid-margin-lg: 32px,
  gutter: 16px,
  max-width: 'xl'
);

//== Focus
$focus-outline-width:       4px;

//== Grid system
//
$grid-gutter-width:         30px !default;
$grid-vertical-gutter:      16px;

//== Navbar
$navbar-default-link-disabled-color: $colour-oyster-grey !default;


//== Product Grids
//
//##

$product-grid-minimum-height: 100vh;


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          $colour-white !default;

//** Popover maximum width
$popover-max-width:                   276px !default;

//** Popover border color
$popover-border-color:                $colour-primary-grey--alpha-40 !default;

//** Popover fallback border color
$popover-fallback-border-color: $colour-oyster-grey !default;

//** Popover title background color
$popover-title-bg: $colour-scallop-grey !default;

//** Popover arrow width
$popover-arrow-width:                 10px !default;

//** Popover arrow color
$popover-arrow-color:                 $popover-bg !default;

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;


//== Carousel
//
//##
$carousel-button-primary:           $colour-earl-grey !default;
$carousel-button-secondary:         $text-color !default;
$carousel-button-colour:            $colour-white !default;
$carousel-caption-color:            $colour-white !default;
$carousel-control-color:            $colour-white !default;
$carousel-control-width:            15% !default;
$carousel-control-opacity:          .5 !default;
$carousel-control-font-size:        20px !default;
$carousel-dot-active:               $colour-waitrose-green-lime !default;
$carousel-dot-inactive:             $carousel-button-primary !default;
$carousel-indicator-active-bg:      $colour-white !default;
$carousel-indicator-border-color:   $colour-white !default;
$carousel-text-shadow:              0 1px 2px $colour-primary-grey--alpha-40 !default;
$carousel-slide-min-height:         354px;


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;

//** Text muted color
$text-muted: $colour-mushroom-grey !default;

//** Abbreviations and acronyms border color
$abbr-border-color: $colour-mushroom-grey !default;

//** Headings small color
$headings-small-color: $colour-mushroom-grey !default;

//** Blockquote small color
$blockquote-small-color: $colour-mushroom-grey !default;

//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) !default;

//** Blockquote border color
$blockquote-border-color: $colour-oyster-grey !default;

//** Horizontal line color.
$hr-border: $colour-oyster-grey !default;


//== Promotions
//
//##
$promo-outline-colour: $colour-oyster-grey !default;
$promo-green:                   $colour-waitrose-green-lime !default;
$promo-background-dark:         $text-color !default;
$promo-text-colour-dark:        $colour-white !default;
$promo-background-light:        $colour-white !default;
$promo-text-colour-light:       $text-color !default;
$promo-background-offer: $colour-offer-red !default;
$promo-text-colour-offer:       $colour-white !default;
$promotion-iconography-sizing:  $grid-vertical-gutter * 3.75; // 60px
$promotion-iconography-spacing: $grid-vertical-gutter * .625; // 10px

// == Conflicts
//
//##
$colour-waitrose-conflict-background: $colour-waitrose-cornfield;

// == Alerts
//
//##
$alert-info-background: $colour-informative-blue-tint;
$alert-error-background: $colour-error-tint;
$alert-warning-background: $colour-warning-tint;
