@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';


.orderCardFooter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $ingredients-unit * 4;
    border-top: solid 1px $colour-mushroom-grey;
    padding-top: 20px;

    @include media-breakpoint-up('sm') {
        flex-direction: row;
        justify-content: space-between;
        gap: $ingredients-unit * 2;
    }

    .statusInfoText {
        display: flex;
        align-items: center;
    }
}

.paymentFailedOrderStatusText {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.closeToCutOff {
    color: $colour-offer-red;
}

.orderCardFooterDark {
    background-color: $colour-primary-grey;
    border-top: none;
    color: $colour-white;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    padding: 20px;
}

.phoneNumber {
    font-weight: bolder;
    text-decoration: underline;
}

.orderCardFooterRed {
    background-color: $colour-error-tint;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    padding: 20px;
}

@mixin icon {
    flex-shrink: 0;
}

.errorIcon {
    @include icon;

    color: $colour-error-red;
}

.actionButtons {
    display: flex;
    flex-direction: column;
    gap: $ingredients-unit * 2;
    align-items: center;

    @include media-breakpoint-up('sm') {
        flex-direction: row;
        gap: $ingredients-unit * 4;
        justify-content: flex-end;
    }

    &>* {
        width: 100%;
        justify-content: center;

        @include media-breakpoint-up('sm') {
            width: auto;
        }
    }
}

.actionButtonsPaymentFailed {
    flex-direction: column;

    @include media-breakpoint-up('sm') {
        flex-direction: row-reverse;
    }
}

.alertContainer {
    padding-top: 20px;
}

/**
* NB: this will change in the future iteration of the epic
*/