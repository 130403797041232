$wdx-transition-time-very-fast: .1s;
$wdx-transition-time-fast: .2s;
$wdx-transition-time-slow: .8s;
$wdx-shake-animation: shake $wdx-transition-time-slow cubic-bezier(.36, .07, .19, .97) both;
$wdx-fade-in-animation: fade-in $wdx-transition-time-fast ease-in both;

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}