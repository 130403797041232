@import '../animation';

%icon {
  display: inline-block;
  font-style: normal;
  vertical-align: middle;

  > svg {
    height: 2em;
    width: 2em;
  }
}

.icon {
  @extend %icon;
}

.iconOld {
  @extend %icon;

  > svg {
    height: 1.25em;
    width: 1.25em;
  }
}

.enter {
  opacity: .01;
  transform: scale(0);
  transition: all $wdx-transition-time-very-fast ease-in;
}

.enterActive {
  opacity: 1;
  transform: scale(1);
  transition: all $wdx-transition-time-very-fast ease-in;
}

.exit,
.exitActive,
.exitDone {
  display: none;
}
